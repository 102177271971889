<template>
  <div class="take-out-center">
    <div class="take-out-item-list">
      <div :class="item.tips ? 'take-out-item mt' : 'take-out-item' " :key="index" @click="go(item)"
           v-for="(item, index) in takeOutList">
        <div class="take-out-item-title">
          <div class="left">
            <img :src="item.logo" class="logo" mode="scaleToFill"/>
            <span>{{ item.title }}</span>
            <span class="tag">{{ item.tag }}</span>
          </div>
          <button class="go" type="primary">
            立即领券
          </button>
        </div>
        <img :src="item.pic" class="main-pic" mode="scaleToFill"/>
        <p class="tips" v-if="item.tips">{{ item.tips }}</p>
      </div>
    </div>
  </div>
</template>

<script>

  const api = require('../../../utils/api').api

  export default {
    name: 'index',
    data () {
      return {
        token: '',
        version: '',
        channel: '',
        url: '',
        appClient: '',
        takeOutList: [
          {
            type: 1,
            logo: 'https://cdn.cailu88.com/jingxuanshi/elm_logo.png',
            title: '饿了么外卖红包',
            tag: '返现',
            pic: 'https://cdn.cailu88.com/jingxuanshi/elmCashback.jpg'
          },
          {
            type: 3,
            logo: 'https://cdn.cailu88.com/jingxuanshi/mt_logo.png',
            title: '美团外卖红包',
            tag: '返现',
            pic: 'https://cdn.cailu88.com/jingxuanshi/mtCashback.jpg?v=1',
            tips: '返佣说明：下单时必须使用领取的红包才会返佣'
          },
          {
            type: 2,
            logo: 'https://cdn.cailu88.com/jingxuanshi/elm_logo.png',
            title: '饿了么买菜到家红包',
            tag: '返现',
            pic: 'https://cdn.cailu88.com/jingxuanshi/elmVegetables.jpg'
          }
        ]
      }
    },
    mounted () {
      this.token = this.$route.query.token
      this.version = this.$route.query.version
      this.channel = this.$route.query.channel
      this.appClient = this.$route.query.appClient

    },
    methods: {
      go (item) {
        this.$router.push({
          name: 'takeoutDetails',
          query: {
            type: item.type,
            token: this.token,
            version: this.version,
            appClient: this.appClient
          }
        })
      }
    },
  }
</script>

<style scoped>
  .take-out-center {
    background: #F9F9F9;
    min-height: 100vh;
    padding: 10px 0;
  }

  .take-out-center .take-out-item-list {
    width: 355px;
    margin: 0 auto;
  }

  .take-out-center .take-out-item-list .take-out-item {
    width: 355px;
    height: 210px;
    background: #FFFFFF;
    border-radius: 10px;
    margin-top: 8px;
    box-sizing: border-box;
    padding: 10px 14px 16px 14px;
  }

  .take-out-center .take-out-item-list .take-out-item:first-child {
    margin-top: 0;
  }

  .take-out-center .take-out-item-list .mt {
    height: 0 !important;
    min-height: 230px !important;
  }

  .take-out-center .take-out-item-list .take-out-item .tips {
    width: 312px;
    height: 17px;
    font-size: 12px;
    font-weight: 400;
    color: #333333;
    line-height: 17px;
    margin-top: 10px;
  }

  .take-out-center .take-out-item-list .take-out-item .take-out-item-title {
    display: flex;
    font-size: 15px;
    font-weight: bold;
    align-items: center;
    justify-content: space-between;
  }

  .take-out-center .take-out-item-list .take-out-item .take-out-item-title .left {
    display: flex;
    align-items: center
  }

  .take-out-center .take-out-item-list .take-out-item .take-out-item-title .left .logo {
    width: 32px;
    height: 32px;
    margin-right: 9px;
  }

  .take-out-center .take-out-item-list .take-out-item .take-out-item-title .left .tag {
    width: 32px;
    height: 16px;
    background: #E44A59;
    border-radius: 4px;
    font-size: 10px;
    line-height: 17px;
    text-align: center;
    color: white;
    margin-left: 5px;
  }

  .take-out-center .take-out-item-list .take-out-item .take-out-item-title .go {
    width: 100px;
    height: 32px;
    background: #FFEA1C;
    border-radius: 16px;
    line-height: 34px;
    color: #333333;
    border: none;
    font-size: 15px;
    outline: none;
  }

  .take-out-center .take-out-item-list .take-out-item .main-pic {
    width: 330px;
    height: 140px;
    border-radius: 10px;
    margin-top: 12px;
  }
</style>
